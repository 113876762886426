<template>
  <!--
         参数：
        content：传进来的数据
        @kindeditorChange：获取数据的事件 传回一个参数：数据
  -->
  <div class="kindeditor">
    <editor
      :id="id"
      :height="height"
      :width="width"
      :content="content"
      :resizeType="resizeType"
       pluginsPath="/kindplugins/"
      :loadStyleMode="false"
      :fullscreenMode="fullscreenMode"
      :afterCreate="afterCreate"
      :uploadJson="uploadUrl"
      @on-content-change="onContentChange"
      ref="editor"
    ></editor>
    <!-- :uploadJson=" rootDir + '/api/Upload/uploadImg'" -->
  </div>
</template>

<script>
import {getUploadUrl} from "@minio/minio"
import Vue from 'vue'
import VueKindEditor from 'vue-kindeditor'
import 'kindeditor/kindeditor-all.js'
import 'kindeditor/themes/default/default.css'
 Vue.use(VueKindEditor);

export default {
  name: "kindeditor",
  data() {
    return {
      items: [
        "fontsize",
        "forecolor",
        "bold",
        "italic",
        "underline",
        "undo",
        "redo",
        "justifyleft",
        "justifycenter",
        "justifyright",
        "justifyfull",
        "wordpaste",
        "selectall",
        "source",
        "image",
        "link",
        "subscript",
        "superscript",
        "insertorderedlist",
        "insertunorderedlist",
        "fullscreen",
      ],
      resizeType:0,
      random:Math.random(),
      uploadUrl:"",
      btnYes:null
    };
  },
  props:{
      width: {
      type: String,
      default: '100%'
    },
      height: {
      type: String,
      default: '400px'
    },
    content:{
      type: String,
    },
    id:{
      type:String,
      default:'editor_id'
    },
    fullscreenMode:{
      type:Boolean,
      default:false
    },
    afterCreate:Function,
    isDeletImg:{
      type:Boolean,
      default:false
    },
     fieldName:{
      type:String,
      default:''
    },
  },
  created(){
    
    // getUploadUrl("medialibrary","imagelibrary/me.png").then(res=>{
    //   this.uploadUrl = res
    //   console.log("minio",res);
    // }).catch(err=>{console.log(err);})

    if(this.isDeletImg){
        this.items = [
          "fontsize",
          "forecolor",
          "bold",
          "italic",
          "underline",
          "undo",
          "redo",
          "justifyleft",
          "justifycenter",
          "justifyright",
          "justifyfull",
          "wordpaste",
          "selectall",
          "link",
          "subscript",
          "superscript",
          "insertorderedlist",
          "insertunorderedlist",
          "fullscreen",
        ]
        
      }
  },
  mounted() {
    let aa = setInterval(function(){
      if(document.getElementsByClassName("ke-dialog-yes").length != 0){
        console.log(document.getElementsByClassName("ke-dialog-yes")); 
        this.btnYes = document.getElementsByClassName("ke-dialog-yes")[0];
        this.btnYes.addEventListener("click",function(){
          console.log("sadasdad");
        })
        clearInterval(aa)
      }else{
        // console.log(document.getElementsByClassName("ke-dialog-yes")); 
      }
    },1000)
    
    // this.$refs.editor.clickToolbar("image", () => {
    //   // 禁用自带的图片弹窗
    //   this.$refs.editor.hideDialog();
    //   // 打开文件
    //   //this.handleOpenFile();
    // });
    console.log("editor",this.$refs.editor);
  },
  methods: {
    onContentChange(val) {
      this.editorText = val;
      this.$emit("kindeditorChange", this.editorText,this.fieldName);
    },
  }
};


</script>

<style>
</style>
