<template>
  <div class="">
    <ul
      ref="scrollContainer"
      class="model-list"
      :class="{ 'f-wrap': source !== 'styles' }"
    >
      <li
        class="item"
        :class="{ select: styleName === item.name }"
        v-for="(item, ind) in styleList"
        :key="ind"
        @click="onSelectModel(item.name)"
      >
        <!-- 栏目配置的风格选择 -->
        <el-image
          v-if="source == 'styles'"
          style="width: 120px; height: 120px"
          :src="item.url"
        >
          <div slot="error" class="image-slot">
            <span>暂无图片</span>
          </div></el-image
        >
        <!-- 基础配置的底部菜单风格 -->
        <el-image v-else style="width: 375px" :src="item.url">
          <div slot="error" class="image-slot">
            <span>暂无图片</span>
          </div></el-image
        >

        <span class="model-name">{{ item.name }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "StyleSelector",
  props: {
    styleName: [String],
    // 样式列表
    styles: [Array],
    // 来源，主要用于区分基础配置(默认)和栏目配置(syles)
    source: [String],
  },
  data() {
    return {
      styleList: [],
    };
  },
  methods: {
    onSelectModel(val) {
      this.$emit("update:styleName", val);
      // this.params.model = data.name;
    },
    initStyleList() {
      // 初始化样式列表
      let reg = /\/(.*?).png/;
      const context = require.context(
        "@/assets/images/styleImage",
        false,
        /\.png$/
      );
      this.styleList = context.keys().map((key) => {
        return {
          // url图片的路径，name为样式的名称
          url: context(key),
          name: key.match(reg)[1],
        };
      });
    },
  },
  watch: {
    styles(val) {
      this.styleList = val;
      this.$nextTick(() => {
        let index = this.styleList.findIndex(
          (item) => item.name === this.styleName
        );
        this.$refs.scrollContainer.scrollLeft = index * 120;
      });
    },
  },
  mounted() {
    if (this.source != "styles") {
      this.initStyleList();
    }
  },
};
</script>

<style lang="scss" scoped>
.model-list,
.img-list {
  display: flex;
  // flex-wrap: wrap;
  align-content: flex-start;
  gap: 8px;
  width: 100%;
  height: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
  }
  &::-webkit-scrollbar {
    width: 0;
    background-color: transparent;
  }

  .item {
    position: relative;
    // width: calc((100% - 16px) / 3);
    // aspect-ratio: 1/1;

    list-style: none;
    border-radius: 4px;
    // overflow: hidden;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    margin-bottom: 5px;

    // .thumb {
    //   display: inline-block;
    //   width: 100%;
    //   height: 100%;

    img {
      display: flex;
      width: 100%;
      // height: 100%;
      // object-fit: cover;
    }
    /deep/.image-slot {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background-color: #f5f7fa;
      color: #82848a;
      width: 100%;
      height: 100%;
    }
    // }

    .model-name,
    .img-name {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      padding: 0 6px;
      color: #fff;
      font-size: 12px;
      line-height: 20px;
      background-color: rgba($color: #000000, $alpha: 0.2);
      box-sizing: border-box;
      text-align: center;
    }
  }

  .select {
    &::before {
      z-index: 9;
      position: absolute;
      left: 0;
      top: 0;
      content: "";
      width: 22px;
      height: 22px;
      background-image: url("../../assets/images/select.svg");
      background-size: 100%;
      background-repeat: no-repeat;
    }
    &::after {
      z-index: 9;
      position: absolute;
      left: 0;
      top: 0;
      content: "";
      width: 100%;
      height: 100%;
      border-radius: 4px;
      box-shadow: inset 0 0 0 2px #2468f2;
    }
  }

  .upload-btn {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    color: #e8e9eb;
    border: 1px dashed #e8e9eb;
    cursor: pointer;
    &:hover {
      color: #2468f2;
      border-color: #2468f2;
    }
    input {
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0;
    }
  }
}
.f-wrap {
  flex-wrap: wrap;
}
</style>




